import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Spinner, SpinnerProps } from "./Spinner";
import { Orbit, OrbitProps } from "./Orbit";

type LoaderType = "spinner" | "orbit";

export interface LoaderProps extends SpinnerProps, OrbitProps {
  type: LoaderType;
  fillContainer?: boolean;
}

const useStyles = makeStyles({
  wrapper: {
    color: "#ccc",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const Loader: React.FC<LoaderProps> = (props) => {
  const classes = useStyles(props);
  const types = {
    spinner: Spinner,
    orbit: Orbit,
  };
  const TagName = types[props.type];

  if (props.fillContainer) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Grid item xs={12} className={classes.wrapper}>
          <TagName {...props} />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div className={classes.wrapper}>
        <TagName {...props} />
      </div>
    );
  }
};

Loader.defaultProps = {
  type: "spinner",
};
