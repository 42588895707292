import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Button,
  Typography,
  Card,
} from "@material-ui/core";
import WebIcon from "@material-ui/icons/Web";
import ContactsIcon from "@material-ui/icons/Contacts";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "left",
      position: "relative",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    loadingOverlay: {
      height: "100%",
      width: "100%",
      position: "absolute",
      display: "flex",
      alignContent: "center",
      flexWrap: "wrap",
      background: "white",
      zIndex: 9,
    },
    content: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    description: {
      marginBottom: theme.spacing(1.5),
    },
    avatar: {
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      backgroundColor: theme.palette.secondary.main,
    },
  })
);

type SectionName =
  | "campaigns"
  | "officers"
  | "constituents"
  | "blueprints"
  | "users";

export interface SectionCardProps {
  loading?: boolean;
  section?: SectionName;
  title: string;
  description?: string;
  paragraph: string;
  actionText: string;
  onActionClick: () => void;
}

function Icon(props: { sectionName: SectionName }) {
  const sections = {
    campaigns: ContactMailIcon,
    officers: HeadsetMicIcon,
    constituents: ContactsIcon,
    blueprints: WebIcon,
    users: HeadsetMicIcon,
  };
  const TagName = sections[props.sectionName];
  return <TagName />;
}

export const SectionCard: React.FC<SectionCardProps> = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          props.section &&
          (props.loading ? (
            <Skeleton variant="circle" width={40} height={40} />
          ) : (
            <Avatar aria-label={props.title} className={classes.avatar}>
              <Icon sectionName={props.section} />
            </Avatar>
          ))
        }
        title={
          <Typography color="primary" variant="h6" component="h2">
            {props.loading ? <Skeleton width="50%" /> : props.title}
          </Typography>
        }
      />
      <CardContent className={classes.content}>
        {props.description && (
          <Typography
            className={classes.description}
            variant="body1"
            paragraph
            color="primary"
            component="p"
          >
            {props.loading ? <Skeleton /> : props.description}
          </Typography>
        )}
        <Typography variant="body1" color="textSecondary" component="p">
          {props.loading ? (
            <>
              <Skeleton height={12} style={{ marginBottom: 6 }} />
              <Skeleton height={12} width="80%" />
            </>
          ) : (
            props.paragraph
          )}
        </Typography>
      </CardContent>
      <CardActions style={{ padding: 16 }}>
        {props.loading ? (
          <Skeleton height={56} width={121} />
        ) : (
          props.actionText && (
            <Button
              color="primary"
              variant="outlined"
              onClick={props.onActionClick}
            >
              {props.actionText}
            </Button>
          )
        )}
      </CardActions>
    </Card>
  );
};
