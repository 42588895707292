import React from "react";
import { Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type Step = { title: string; paragraph: string; buttonText: string };

export interface OnboardingProps {
  steps: Step[];
  onDone: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
}));

export const Onboarding: React.FC<OnboardingProps> = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const onNext = () => {
    if (activeStep === props.steps.length - 1) {
      handleClose();
    } else {
      setActiveStep((previous) => previous + 1);
    }
  };

  // DIALOG
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setTimeout(props.onDone, 500);
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={10} sm={6} md={4}>
            {props.steps.map((step, index) => (
              <div
                key={step.title}
                style={{ display: index === activeStep ? "block" : "none" }}
              >
                <Box mb={4}>
                  <div>
                    <Typography gutterBottom color="primary" variant="h4">
                      {step.title}
                    </Typography>
                    <Typography gutterBottom variant="body1">
                      {step.paragraph}
                    </Typography>
                  </div>
                </Box>
                <Button color="primary" variant="contained" onClick={onNext}>
                  {step.buttonText}
                </Button>
              </div>
            ))}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};
