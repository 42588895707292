import React from "react";
import { AlertTitle, Alert as MuiAlert } from "@material-ui/lab";
import { Button, Collapse, Slide } from "@material-ui/core";

export interface AlertProps {
  type: "success" | "info" | "warning" | "error";
  variant?: "outlined" | "filled" | "standard";
  title?: string;
  message: string;
  onAction?: () => void;
  actionText?: string;
  dismissible?: boolean;
  onClose?: () => void;
  open?: boolean;
  className?: string;
  fullscreen?: boolean;
}

export const Alert: React.FC<AlertProps> = ({
  variant = "standard",
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleClose = () => {
    setIsOpen(false);
    if (props.onClose) props.onClose();
  };

  if (!props.fullscreen) {
    return (
      <div>
        <Collapse in={props.open !== undefined ? props.open : isOpen}>
          <MuiAlert
            className={props.className}
            variant={variant}
            onClose={props.dismissible ? handleClose : undefined}
            action={
              props.actionText &&
              props.onAction && (
                <Button color="inherit" size="small" onClick={props.onAction}>
                  {props.actionText}
                </Button>
              )
            }
            severity={props.type}
          >
            {props.title && <AlertTitle>{props.title}</AlertTitle>}
            {props.message}
          </MuiAlert>
        </Collapse>
      </div>
    );
  } else {
    return (
      <Slide direction="up" in={props.open !== undefined ? props.open : isOpen}>
        <MuiAlert
          className={props.className}
          severity={props.type}
          variant="filled"
          onClose={props.dismissible ? handleClose : undefined}
          action={
            props.actionText &&
            props.onAction && (
              <Button color="inherit" size="small" onClick={props.onAction}>
                {props.actionText}
              </Button>
            )
          }
          style={{
            position: "fixed",
            top: "auto",
            bottom: 0,
            padding: 16,
            left: 0,
            width: "100%",
            boxSizing: "border-box",
            borderRadius: 0,
          }}
        >
          {props.title && (
            <AlertTitle>
              <strong>{props.title}</strong>
            </AlertTitle>
          )}
          {props.message}
        </MuiAlert>
      </Slide>
    );
  }
};
