import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Grid, Box } from "@material-ui/core";

export interface EmptyStateProps {
  image?: string;
  title: string;
  paragraph?: string;
  buttonInstructions?: string;
  buttonText: string;
  buttonDisabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  uploadButtonInstructions?: string;
  uploadButtonText?: string;
  uploadButtonDisabled?: boolean;
  onUploadClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const useStyles = makeStyles(() => ({
  centredContainer: {
    minHeight: "100%",
    height: "100%",
  },
  input: {
    display: "none",
  },
  containerItem: {
    marginRight: "16px",
    marginBottom: "16px",
  },
}));

export const EmptyState: React.FC<EmptyStateProps> = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={classes.centredContainer}
    >
      <Grid item xs={10} sm={8} md={6}>
        {props.image && (
          <Box
            style={{
              backgroundImage: `url('${props.image}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: 200,
            }}
            mb="40px"
          ></Box>
        )}
        <Box mb="40px">
          <Typography variant="h3" component="h1" color="primary" gutterBottom>
            {props.title}
          </Typography>
          <Typography variant="body1">{props.paragraph}</Typography>
        </Box>
        <Grid container item direction="row" alignItems="stretch">
          {props.buttonText && (
            <Grid className={classes.containerItem} item xs={10} sm={8} md={6}>
              <Typography paragraph>{props.buttonInstructions}</Typography>
              <Button
                color="primary"
                variant="contained"
                disabled={!!props.buttonDisabled}
                onClick={props.onClick}
              >
                {props.buttonText}
              </Button>
            </Grid>
          )}
          {props.uploadButtonText && (
            <Grid className={classes.containerItem} item xs={10} sm={8} md={6}>
              <Typography paragraph>
                {props.uploadButtonInstructions}
              </Typography>
              <Button
                color="primary"
                variant="contained"
                disabled={!!props.uploadButtonDisabled}
                onClick={props.onUploadClick}
              >
                {props.uploadButtonText}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
