import React from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

export interface SpinnerProps {
  anonymous?: boolean;
  backgroundColor?: string;
  value?: number;
  size?: number;
  variant?: "determinate" | "indeterminate";
}

const useStyles = makeStyles({
  spinner: {
    position: "absolute",
    left: 0,
    strokeLinecap: "round",
  },
  backgroundCircle: {
    color: (props: SpinnerProps) => props.backgroundColor,
  },
});

export const Spinner: React.FC<SpinnerProps> = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <CircularProgress
        variant="determinate"
        size={props.size}
        thickness={2.4}
        value={100}
        className={classes.backgroundCircle}
      />
      <CircularProgress
        variant={props.variant}
        size={props.size}
        thickness={2.4}
        color={props.anonymous ? "inherit" : "primary"}
        value={props.value}
        className={classes.spinner}
      />
    </>
  );
};

Spinner.defaultProps = {
  value: 50,
  variant: "indeterminate",
  size: 40,
  backgroundColor: "#eee",
};
