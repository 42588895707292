import React from "react";
import {
  Theme,
  WithStyles,
  createStyles,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton,
  LinearProgress,
  Slide,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(
  // Ignored this as it was copied from MUI docs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Add close button to allow closing the dialog on mobile
const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const CustomTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

export interface FormDialogProps {
  open: boolean;
  title?: string;
  helpText?: string;
  onClose: () => void;
  isSubmitting: boolean;
  resetForm: () => void;
  formId: string;
  ctaText?: string;
  closeText?: string;
  maxWidth?: DialogProps["maxWidth"];
  dirty?: boolean;
}

export const FormDialog: React.FC<FormDialogProps> = ({
  ctaText = "Submit",
  closeText = "Cancel",
  maxWidth = "xs",
  ...props
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = () => {
    props.onClose();
    props.resetForm();
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        maxWidth={maxWidth}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        aria-labelledby="form-dialog-title"
      >
        {props.title && (
          <CustomTitle id="form-dialog-title" onClose={handleClose}>
            {props.title}
          </CustomTitle>
        )}
        <DialogContent dividers>
          {props.helpText && (
            <DialogContentText>{props.helpText}</DialogContentText>
          )}
          {props.children}
        </DialogContent>
        {props.isSubmitting && <LinearProgress />}
        <DialogActions>
          <Button
            variant="outlined"
            disabled={props.isSubmitting}
            onClick={handleClose}
            color="primary"
          >
            {closeText}
          </Button>
          <Button
            variant="contained"
            disabled={props.isSubmitting || props.dirty === false}
            form={props.formId}
            type="submit"
            color="primary"
          >
            {ctaText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
