import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { TransitionProps } from "@material-ui/core/transitions";
import { LinearProgress, Slide } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(
  // Ignored this as it was copied from MUI docs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

export interface DeleteConfirmationDialogProps {
  onDelete: () => void;
  onClose: () => void;
  open: boolean;
  isDeleting: boolean;
  title?: string;
  text?: string;
}

export const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = (
  props
) => {
  const handleClose = () => {
    props.onClose();
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xs"
        TransitionComponent={Transition}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {props.title || "Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: "pre-line" }}>
            {props.text ||
              `Do you really want to delete this?
               This action cannot be undone!`}
          </DialogContentText>
        </DialogContent>
        {props.isDeleting && <LinearProgress />}
        <DialogActions>
          <Button autoFocus onClick={handleClose} disabled={props.isDeleting}>
            Cancel
          </Button>
          <DeleteButton
            onClick={props.onDelete}
            autoFocus
            disabled={props.isDeleting}
          >
            Delete
          </DeleteButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
