import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export interface OrbitProps {
  anonymous?: boolean;
  size?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      transform: "rotateZ(45deg)",
      perspective: 1000,
      borderRadius: "50%",
      width: (props: OrbitProps) => props.size,
      height: (props: OrbitProps) => props.size,
      color: (props: OrbitProps) =>
        props.anonymous ? "#ccc" : theme.palette.primary.main,
      "&:before, &:after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        left: 0,
        width: "inherit",
        height: "inherit",
        borderRadius: "50%",
        transform: "rotateX(70deg)",
        animation: "1s $spin linear infinite",
      },
      "&:after": {
        color: (props: OrbitProps) =>
          props.anonymous ? "#999" : theme.palette.secondary.main,
        transform: "rotateY(70deg)",
        animationDelay: ".4s",
      },
    },

    "@keyframes spin": {
      "0%, 100%": {
        boxShadow: ".2em 0px 0 0 currentcolor",
      },
      "12%": {
        boxShadow: ".2em .2em 0 0 currentcolor",
      },
      "25%": {
        boxShadow: "0 .2em 0 0 currentcolor",
      },
      "37%": {
        boxShadow: "-.2em .2em 0 0 currentcolor",
      },
      "50%": {
        boxShadow: "-.2em 0 0 0 currentcolor",
      },
      "62%": {
        boxShadow: "-.2em -.2em 0 0 currentcolor",
      },
      "75%": {
        boxShadow: "0px -.2em 0 0 currentcolor",
      },
      "87%": {
        boxShadow: ".2em -.2em 0 0 currentcolor",
      },
    },
  })
);

export const Orbit: React.FC<OrbitProps> = (props) => {
  const classes = useStyles(props);
  return <span className={classes.loader}></span>;
};

Orbit.defaultProps = {
  size: 96,
};
